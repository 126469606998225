var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.counterparties.title"))+" ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.hasCreateAccess)?_c('v-btn',{attrs:{"color":"primary","small":"","fab":""},on:{"click":function($event){return _vm.showCounterpartyFormSideBar()}}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1)],1),_c('v-row',{staticClass:"align-center mt-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"label":_vm.$lang('shared.search'),"background-color":_vm.dark ? '#1E1E1E' : 'white',"append-icon":"search","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.counterparties,"expanded":_vm.expanded,"loading":_vm.loading,"search":_vm.search,"items-per-page":50,"footer-props":{
          itemsPerPageOptions: [25, 50, 100, -1],
        },"custom-filter":_vm.filterByCounterpartyAndNetworkName,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.handleClickByTableRow},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
        var item = ref.item;
        var isExpanded = ref.isExpanded;
        var expand = ref.expand;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(_vm.canExpand(item))?_c('v-btn',_vm._g({attrs:{"text":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},on),[_c('v-icon',{style:(isExpanded
                      ? 'transform: rotate(90deg);'
                      : 'transform: rotate(0deg);')},[_vm._v(" mdi-chevron-right ")])],1):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.counterparties.showCurrentNetworks"))+" ")])]}},{key:"item.currentBankName",fn:function(ref){
                      var item = ref.item;
return [(!item.currentBankName && !item.currentBankId)?_c('router-link',{attrs:{"to":{
              name: 'counterpartyBanks',
              params: {
                counterpartyId: item.id,
              },
            }}},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.counterparties.fillBanks"))+" ")]):[_vm._v(" "+_vm._s(item.currentBankName)+" ")]]}},{key:"item.taxation",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$lang( ("accountingPortal.counterparties.taxation." + (item.taxation.toLowerCase())) ))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(_vm.hasEditAccess)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showCounterpartyFormSideBar(item)}}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("btn.edit")))])]):_vm._e(),(_vm.hasDeleteAccess)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showDeleteDialog(item)}}},on),[_c('v-icon',[_vm._v(" delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("btn.delete")))])]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [(item.currentNetworks.length)?_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-0 rounded-0 cursor-none",style:({ background: _vm.dark ? '#424242' : '#f5f5f5' }),attrs:{"headers":_vm.EXPANDED_HEADERS,"items":item.currentNetworks,"hide-default-footer":"","tile":""},on:{"click:row":_vm.handleClickByExpandedTableRow}})],1):_vm._e()]}}],null,true)})],1)],1),_c('counterparty-form',{attrs:{"editing-item":_vm.editingItem},model:{value:(_vm.isSideBarVisible),callback:function ($$v) {_vm.isSideBarVisible=$$v},expression:"isSideBarVisible"}}),_c('confirmation-dialog',{attrs:{"loading":_vm.isCounterpartyDeleting,"title":_vm.$lang('accountingPortal.counterparties.deleteDialog.title'),"text":_vm.$lang('accountingPortal.counterparties.deleteDialog.body'),"btn-text":_vm.$lang('btn.delete')},on:{"confirm":_vm.deleteCounterparty},model:{value:(_vm.isDeleteDialogVisible),callback:function ($$v) {_vm.isDeleteDialogVisible=$$v},expression:"isDeleteDialogVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }